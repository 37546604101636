const modal = document.getElementById('modal-product');
const content = document.getElementById('modal-content');
const overlay = document.getElementById('modal-overlay');
const closeButton = document.getElementById('close-modal');

document.querySelectorAll('.modal-block').forEach((el)=>{
    let productUrl = el.dataset.url
    el.addEventListener('click',function(e){open(e,productUrl)})
})

overlay.addEventListener('click',function(e){
  close(e)
})      
closeButton.addEventListener('click',function(e){
  close(e)
})    

function getPageContent(url){
    fetch(url).
    then(response => {
      if (response.ok) {
        return response.text()
      }
    })
    .then(html => {
      content.innerHTML = html
    })
}

function open(e, url){
    e.preventDefault()
    modal.classList.add('fixed');
    modal.classList.remove('hidden');
    overlay.classList.add('fixed');
    overlay.classList.remove('hidden');

    getPageContent(url)
}

function close(e){
    e.preventDefault()
    modal.classList.remove('fixed');
    modal.classList.add('hidden');
    overlay.classList.add('hidden');
    overlay.classList.remove('fixed');

    content.innerHTML = "Chargement du contenu"
}



